import React from 'react'
import styled from 'styled-components'
import {
  ShippingText,
  LargerHeading,
  DetailsText,
  CrumbCon,
  HeadingText,
} from '../../styles'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import media from '../../styles/media'
import { isMobile } from 'react-device-detect'
import Navigation from '../Navigation'

const ShippingInfoCon = styled.div`
  margin: var(--gutter-m) var(--gutter-s);
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${media.laptop`
    margin: var(--gutter-m) var(--gutter-m);
  `}
`

const Shipping = () => {
  return (
    <>
      <Navigation />
      <CrumbCon>
        <Breadcrumb>
          <Breadcrumb.Item href="/products">
            <p className="blue backArrow">
              &#x25C2;
            </p>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <p>/</p>
          <Breadcrumb.Item href="/shipping">
            Shipping &amp; Returns
          </Breadcrumb.Item>
        </Breadcrumb>
      </CrumbCon>
      <ShippingInfoCon>
        {!isMobile && (
          <div className="heading">
            <HeadingText className="black smaller">
              Shipping &amp; Returns
            </HeadingText>
            <DetailsText>Last updated: 19 August 2020</DetailsText>
          </div>
        )}
        {isMobile && (
          <>
            <DetailsText>Last updated: 19 August 2020</DetailsText>
            <LargerHeading>Shipping &amp; Returns</LargerHeading>
          </>
        )}
        <ShippingText className="bold">
          <br />
          <br /> Please note that special terms apply to Consumers who wish to
          return goods, which prevail over the provisions of this Clause 8.
          Customers who are Consumers are referred to Clause 13.
        </ShippingText>
        <ShippingText>
          <br />
          <br /> 1) Quza IT is committed to providing our customers with the
          highest quality products and service. However, on rare occasions,
          products may be found to be faulty or defective. In such cases we
          offer the returns facilities described below.
          <br />
          <br /> 2) Unless otherwise stated in the manufacturer's documentation,
          all goods delivered to a South African address carry a 12-month
          manufacturer's warranty.
          <br />
          <br /> 3) If you purchase goods in the course of your business, the
          following provisions of this Clause shall apply. Other than the
          express provisions set out in these terms and conditions, all other
          terms and the implied terms or warranties relating to the supply of
          goods are excluded to the fullest extent permitted by law. Goods are
          not tested or sold as being fit for any particular application or for
          use under specific conditions, unless expressly agreed in writing.
          <br />
          <br /> 4) If you purchase services in the course of your business, the
          following provisions of this Clause shall apply. Quza IT shall use its
          skill and expertise to carry out any contracted works (the
          "Service(s)") to a standard equivalent to that of a competent computer
          professional, and shall warrant our work as free from defects, for a
          period of 30 days after completion. In particular, we cannot be held
          responsible for any fault or damage not caused by Quza IT services'
          engineers or its contracted agents. In the event of a claim arising
          relating to the level of skill and judgement applied in the course of
          providing Services, Quza IT reserves at its sole discretion the right
          to appoint an independent expert in the field to appraise the work
          carried out in the execution of the Service(s). Additionally, Quza IT
          cannot be held responsible for equipment installed or configured when
          the equipment has subsequently been altered or configured by persons
          other than Quza IT. Except as set out here, all other express or
          implied terms or warranties relating to the Services are excluded to
          the fullest extent permitted by law.
          <br />
          <br /> 5) Subject to the right of Consumers to return goods for refund
          under The Consumer Protection Act (see Clause 13), Quza IT does not
          sell products on a trial basis. Customers are strongly advised to
          check suitability and specifications of products before ordering. In
          some instances, Customers may benefit from special price discounts
          issued by a manufacturer specifically for their benefit. Such goods
          are not returnable to the manufacturer and may not be sold to other
          customers. Accordingly, orders for such goods cannot be cancelled and
          Quza IT can only accept a return of such Goods where they prove to be
          defective and the Goods are returned for repair or replacement.
          <br />
          <br /> 6) In the event that Quza IT, at its discretion agrees to
          accept the return for credit of unwanted products, the goods must be
          returned with Quza IT's prior written agreement within 14 days of
          delivery. The goods must be unopened and in perfect re-saleable
          condition. All goods returned in these circumstances will be subject
          to a handling fee of 15% of Quza IT's sale price for the goods, or
          R250, whichever is the greater.
          <br />
          <br /> 7) Subject to testing to verify any alleged fault, we will
          accept the return of defective goods for full refund or replacement at
          our option, if, but only if, the goods are returned within 14 days of
          delivery. Consumers who wish to return defective products are not
          obliged to follow the processes set out below, but are recommended to
          do so as this helps us to provide a more efficient returns service.
          <br />
          <br /> a. Quza IT's technical support staff or Customer Support staff,
          as appropriate, will advise you of which method of delivery to use to
          return the products. Depending on the nature of the product purchased,
          we will either arrange a courier collection, or request that you
          return the product directly to us. If the goods are found on
          inspection to be defective, the cost of returning the item will be
          refunded to you.
          <br />
          <br /> b. All returned goods (except those returned under Clause 13)
          must be accompanied by Quza IT's Returns Authorisation number ('RA
          Number') which can be obtained by contacting Customer Support.
          Returned goods will not be accepted without an RA Number. Do not write
          directly on the manufacturer's packaging. Please write the RA number
          on the address label provided with the Returns Authorisation and
          attach it to the returned package. Any defacement of the
          manufacturer's packaging or damage caused by inadequate packaging may
          result in the rejection of the return or an additional restocking fee,
          at Quza IT's sole discretion.
          <br />
          <br /> c. Quza IT cannot accept liability for packages damaged during
          transit. It is the Customer's responsibility to wrap the product
          adequately to prevent damage.
          <br />
          <br /> d. Proof of postage is not proof of delivery and you are
          therefore strongly advised to send your package by recorded delivery,
          registered post or courier, and to insure the goods for their full
          value.
          <br />
          <br /> e. On receipt of the returned product, we will test it to
          identify the fault you have notified to us.
          <br />
          <br /> f. If following the testing process, the product is found to be
          in good working order without defect, we will return the product to
          you, and the carriage costs of this return will be your
          responsibility. Please note that if you have, in the meantime,
          required us to provide you with a replacement product before
          completion of the testing process, you will have to pay for this
          product also. This Clause does not apply to Consumers returning goods
          pursuant to Clause 13.
        </ShippingText>
      </ShippingInfoCon>
    </>
  )
}

export default Shipping
