import React from 'react'

import SEO from '~/components/seo'
import Shipping from '../components/Shipping'
import Footer from '../components/Footer'

const ShippingPage = () => (
  <>
    <SEO
      title="Shipping &amp; Returns"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Shipping />
    <Footer />
  </>
)

export default ShippingPage
